<template>
  <div class="v-tools o-wrapper u-px u-py--2">
    <FHHeading header="h1" :text="$t('TOOLS_HEADING')" />
    <div class="v-tools__header">
      <div class="v-tools__text">
        {{ $t('TOOLS_TEXT') }}
      </div>
    </div>
    <div class="v-tools__content">
      <div class="v-tools__item">
        <FHHeading header="h2" text="Leseverktøy" />
        <div class="v-tools__item-cols">
          <div class="v-tools__item-text">
            Leseverktøyet er en digital samling med seksti tekster i ulike sjangre. Tekstene er delt inn etter vanskelighetsgrad, og kommer med oppgaver som tar for seg analyse, grammatikk og fagbegreper.
          </div>
          <div class="v-tools__item-btn-wrapper">
            <FHButton class="v-tools__btn" target="_blank" href="https://leseverktoy.app.fagbokforlaget.no/">
              Til Leseverktøy
            </FHButton>
          </div>
        </div>
      </div>

      <div class="v-tools__item">
        <FHHeading header="h2" text="Muntligverktøy" />
        <div class="v-tools__item-cols">
          <div class="v-tools__item-text">
            Se en film om hva som kjennetegner en god presentasjon! Finn ut hva du bør vektlegge i en fagsamtale. I Muntligverktøy finner du veiledninger og forklaringer til de viktigste muntlige vurderingsformene i norskfaget. Finn ut hvilken muntligtype du er, og hva du kan gjøre for å ta det neste steget opp og fram.
          </div>
          <div class="v-tools__item-btn-wrapper">
            <FHButton class="v-tools__btn v-tools__btn" target="_blank" href="https://muntligverktoy.fagbokforlaget.no/">
              Til Muntligverktøy
            </FHButton>
          </div>
        </div>
      </div>

      <div class="v-tools__item">
        <FHHeading header="h2" text="Synopsis Grammatikk" />
        <div class="v-tools__item-cols">
          <div class="v-tools__item-text">
            Bli kjent med mulighetene i språket! Synopsis Grammatikk er grammatikkundervisning på en helt ny måte. Ta med deg den forlatte roboten Sven til ulike steder og gjør ham til en trygg språkbruker.
          </div>
          <div class="v-tools__item-btn-wrapper">
            <FHButton class="v-tools__btn v-tools__btn" target="_blank" href="https://synopsis.grammatikk.fagbokforlaget.no/">
              Til Synopsis Grammatikk
            </FHButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FHHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'

export default {
  name: 'Tools',

  components: {
    FHHeading,
    FHButton
  }
}
</script>

<style lang="scss">
.v-tools__content {
  margin-top: 2rem;
  margin-bottom: 5rem;
  max-width: 1030px;
}

.v-tools__text {
  font-size: 1.5rem;
}

.v-tools__item {
  margin-top: 2.5rem;

  h2 {
    font-size: 1.125rem;
  }
}

.v-tools__item-cols {
  @include bp(578px) {
    display: flex;
    justify-content: space-between;
  }
}

.v-tools__item-text {
  max-width: 587px;
  padding-right: 2rem;
}

.v-tools__btn {
  display: inline-block;
  text-align: center;
  margin-top: 1.5rem;
  @include bp(578px) {
    margin-top: 0;
  }
  background: $btn-with-text;
  border: none;
  color: $color-white;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 5px;
  padding: 0.875rem 2.1875rem;
  min-width: 9.5rem;

  &--disabled {
    background: $color-light-mid-grey;
    cursor: not-allowed;
  }
}
</style>